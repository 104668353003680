
import { i18n } from '@/i18n.js'

import { translateDay, translatedDayAndMonth, ISO8601StringToDate, isValidISO8601Date } from '@/helpers/DateTime.js'

const FrequencyTypes = {
  EveryYear: 'every_year',
  EveryXWeek: 'every_x_weeks',
  ExectDate: 'exect_date',
  EveryDay: 'every_day',
  EveryWeek: null
}

const millisecondsInDay = 60 * 60 * 24 * 1000

const operationFrequencyOptionsForSelect = () => {
  const exectDateFrequency = {
    frequency: null,
    frequencyType: FrequencyTypes.ExectDate,
    translatedName: i18n.t('operation_frequency.exect_date_frequency')
  }

  const everyYearFrequency = {
    frequency: null,
    frequencyType: FrequencyTypes.EveryYear,
    translatedName: i18n.t('operation_frequency.every_year_no_date')
  }

  const everyDayFrequency = {
    frequency: null,
    frequencyType: FrequencyTypes.EveryDay,
    translatedName: i18n.t('operation_frequency.every_day')
  }

  const everyWeekFrequency = {
    default: true,
    frequency: 1,
    frequencyType: FrequencyTypes.EveryXWeek,
    translatedName: i18n.t('operation_frequency.every_week')
  }
  const result = [
    exectDateFrequency, everyYearFrequency, everyDayFrequency, everyWeekFrequency
  ]
  // array from 2 to 12
  const frequencyOptions = Array.from({ length: 11 }, (_, k) => k + 2).map((frequency) => {
    return {
      frequency,
      frequencyType: FrequencyTypes.EveryXWeek,
      translatedName: i18n.t('operation_frequency.week_x_frequency', { frequency })
    }
  })
  return result.concat(frequencyOptions)
}

const getFrequencyText = ({ frequency, frequencyType, dateString = null } = {}) => {
  if (frequency && frequencyType === FrequencyTypes.EveryXWeek) {
    return i18n.t('operation_frequency.week_x_frequency', { frequency })
  }
  if (frequencyType === FrequencyTypes.EveryDay) {
    return i18n.t('operation_frequency.every_day')
  }
  if (frequencyType === FrequencyTypes.EveryYear && isValidISO8601Date(dateString)) {
    const date = ISO8601StringToDate(dateString)
    return i18n.t('operation_frequency.every_year', { date: translatedDayAndMonth(date) })
  }
}

const getFrequencyTextWithDays = ({ frequency, frequencyType, dayName = null, dateString = null } = {}) => {
  if (frequencyType === FrequencyTypes.EveryWeek) {
    if (dayName) {
      return i18n.t('operation_frequency.every_week_with_day', { dayName: translateDay(dayName) })
    } else {
      return i18n.t('operation_frequency.every_week')
    }
  }

  if (frequency && frequencyType === FrequencyTypes.EveryXWeek) {
    if (dayName) {
      return i18n.t('operation_frequency.week_x_frequency_with_day', { frequency, dayName: translateDay(dayName) })
    } else {
      return i18n.t('operation_frequency.week_x_frequency', { frequency })
    }
  }

  if (frequencyType === FrequencyTypes.EveryDay) {
    return i18n.t('operation_frequency.every_day')
  }
  if (frequencyType === FrequencyTypes.EveryYear && isValidISO8601Date(dateString)) {
    const date = ISO8601StringToDate(dateString)
    return i18n.t('operation_frequency.every_year', { date: translatedDayAndMonth(date) })
  }
}

const frequencyTextFromTask = ({ task, dayName = null, dateString = null } = {}) => {
  const frequencyType = task.frequency_type
  const frequency = task.frequency

  if (frequencyType === FrequencyTypes.EveryWeek) {
    if (dayName) {
      return i18n.t('operation_frequency.every_week_with_day', { dayName: translateDay(dayName) })
    } else {
      return i18n.t('operation_frequency.every_week')
    }
  }

  if (frequency && frequencyType === FrequencyTypes.EveryXWeek) {
    if (dayName) {
      return i18n.t('operation_frequency.week_x_frequency_with_day', { frequency, dayName: translateDay(dayName) })
    } else {
      return i18n.t('operation_frequency.week_x_frequency', { frequency })
    }
  }

  if (frequencyType === FrequencyTypes.EveryDay) {
    return i18n.t('operation_frequency.every_day')
  }
  if (frequencyType === FrequencyTypes.EveryYear && isValidISO8601Date(dateString)) {
    const date = ISO8601StringToDate(dateString)
    return i18n.t('operation_frequency.every_year', { date: translatedDayAndMonth(date) })
  }

  if (frequencyType === FrequencyTypes.ExectDate && task.operation_next_date) {
    if (task.endDateUserFormat) {
      return i18n.t('account_operations.op_original_date_with_end_date', {
        date: task.operation_next_date, endDate: task.endDateUserFormat
      })
    }
    return i18n.t('account_operations.op_original_date', { date: task.operation_next_date })
  }
}

const calculateCurrentStartPeriodForEveryXWeeksFrequency = (frequency, firstScheudled, date = new Date()) => {
  const daysDiff = Math.round((date - firstScheudled) / millisecondsInDay)
  const weeklyFraction = daysDiff / (7 * frequency)

  const result = new Date(firstScheudled.getTime())
  result.setDate(result.getDate() + 7 * frequency * Math.floor(weeklyFraction))
  return result
}


const frequencyOptionsForSelectUsingSingleType = () => {
  const result = [
    {
      value: FrequencyTypes.ExectDate,
      name: i18n.t('operation_frequency.exect_date_frequency')
    },
    {
      value: FrequencyTypes.EveryYear,
      name: i18n.t('operation_frequency.every_year_no_date')
    },
    {
      value: FrequencyTypes.EveryDay,
      name: i18n.t('operation_frequency.every_day')
    },
    {
      value: 'every_1_weeks',
      name: i18n.t('operation_frequency.every_week')
    }
  ]
  const frequencyOptions = Array.from({ length: 11 }, (_, k) => k + 2).map((frequency) => {
    return {
      value: `every_${frequency}_weeks`,
      name: i18n.t('operation_frequency.week_x_frequency', { frequency })
    }
  })
  return result.concat(frequencyOptions)
}

const translateFrequencyUsingOneType = (frequencyType) => {
  if (frequencyType.startsWith('every_') && frequencyType.endsWith('_weeks')) {
    const frequency = parseInt(frequencyType.split('_')[1])
    if (frequency === 1) return i18n.t('operation_frequency.every_week')
    return i18n.t('operation_frequency.week_x_frequency', { frequency })
  }
  if (frequencyType === FrequencyTypes.ExectDate) {
    return i18n.t('operation_frequency.exect_date_frequency')
  }
  if (frequencyType === FrequencyTypes.EveryYear) {
    return i18n.t('operation_frequency.every_year_no_date')
  }
  if (frequencyType === FrequencyTypes.EveryDay) {
    return i18n.t('operation_frequency.every_day')
  }
}
export {
  FrequencyTypes,
  operationFrequencyOptionsForSelect,
  getFrequencyText,
  getFrequencyTextWithDays,
  calculateCurrentStartPeriodForEveryXWeeksFrequency,
  frequencyTextFromTask,
  frequencyOptionsForSelectUsingSingleType,
  translateFrequencyUsingOneType
}
